<template>
    <div class="justify-content-center">
        <a 
            href='https://play.google.com/store/apps/details?id=com.ridethebus&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
            target="_blank"    
        >
            <img 
                alt='Get it on Google Play'
                src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                width=250em
            />
        </a>
        <a href="https://www.buymeacoffee.com/ridethebus" target="_blank"><b-button class="beer-button">🍻 Buy me a beer 😳</b-button></a>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    }
}
</script>
<style scoped>
    .beer-button {
        width: 235px;
        height: 63px;
        color: #fff;
        background-color: #000000;
        border-color: #ffffff;
        font-size: 1.25rem;
        text-decoration: none;
    }
    .beer-button:hover {
        color: #fff;
        background-color: #23272b;
        border-color: #000000;
    }
</style>